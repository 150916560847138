import products from "./products";
import rfqs from "./rfqs";
import orders from "./orders";
import transactions from "./transactions";
import vendorCars from "./vendorCars";
import withdrawals from "./withdrawals";
import approvedVendor from "./guards/approvedVendor";

const routes = [
    {
        path: "/contul-meu",
        name: "dashboard.show",
        beforeEnter: [approvedVendor],
        component: () =>
            import(/* webpackChunkName: "dashboard.show" */ "../views/Dashboard.vue"),
        meta: {
            requiresAuth: true,
        },
    },

];

export default routes.concat(products, rfqs, orders, transactions, withdrawals, vendorCars)