<template>
  <section class="text-gray-600 body-font">
    <div class="container max-w-7xl px-4 py-24 mx-auto flex flex-wrap">
      <div
        class="
          flex flex-wrap
          -mx-4
          mt-auto
          mb-auto
          lg:w-1/2
          sm:w-2/3
          content-start
          sm:pr-10
        "
      >
        <div class="w-full px-4 mb-6">
          <h2
            class="
              mb-2
              text-3xl
              leading-8
              font-bold
              tracking-tight
              text-gray-900
              sm:text-4xl
            "
          >
            Tu vii cu piesa, noi venim cu clientul
          </h2>
          <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Simplu și rapid, te anunțam cand un client cauta o anumita piesa.
            Parcul auto trebuie doar sa accese platforma in termen de 24h si sa
            faca o oferta pentru piesa respectiva.
          </p>
        </div>

        <div
          v-for="stat in statistics"
          :key="stat.name"
          class="p-4 sm:w-1/2 w-1/2"
        >
          <h2 class="title-font font-medium text-3xl text-gray-900">
            {{ stat.name }}
          </h2>
          <p class="leading-relaxed">{{ stat.description }}</p>
        </div>
      </div>
      <div
        class="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0"
      >
        <img
          class="object-cover object-center w-full h-full"
          src="https://pash.ro/wp-content/uploads/2021/10/Automotive_aftermarket_excess_stock.jpg"
          alt="stats"
        />
      </div>
    </div>
  </section>
</template>

<script>
import {
  LightningBoltIcon,
  ScaleIcon,
  CreditCardIcon,
  ShieldCheckIcon,
} from "@heroicons/vue/outline";

const statistics = [
  {
    name: "24",
    description: "ONLINE 24/24",
    icon: ScaleIcon,
  },
  {
    name: "+100",
    description: "PARCURI AUTO",
    icon: LightningBoltIcon,
  },
  {
    name: "+3000",
    description: "PIESE AUTO",
    icon: CreditCardIcon,
  },
  {
    name: "100%",
    description: "CLIENȚI SATISFĂCUȚI",
    icon: ShieldCheckIcon,
  },
];

export default {
  setup() {
    return {
      statistics,
    };
  },
};
</script>
