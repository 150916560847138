import approvedVendor from "./guards/approvedVendor";

export default [
    //produse
    {
        path: "/produse",
        name: "products.index",
        component: () =>
            import(
          /* webpackChunkName: "products.index" */ "../views/ProductsIndex.vue"
            ),
        beforeEnter: [approvedVendor],
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Dashboard", href: "/contul-meu", current: false },
                { name: "Produse", href: "/produse", current: true },
            ],
        },
    },
    {
        path: "/produse/adauga",
        name: "products.create",
        component: () =>
            import(
          /* webpackChunkName: "products.create" */ "../views/ProductsCreate.vue"
            ),
        beforeEnter: [approvedVendor],
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Dashboard", href: "/contul-meu", current: false },
                { name: "Produse", href: "/produse", current: false },
                { name: "Adauga produs", href: "/produse/adauga", current: true },
            ],
        },
    },
    {
        path: "/produse/:id",
        name: "products.show",
        component: () =>
            import(
          /* webpackChunkName: "products.show" */ "../views/ProductsShow.vue"
            ),
        beforeEnter: [approvedVendor],
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumbs: (route) => {
                return [
                    { name: "Dashboard", href: "/contul-meu", current: false },
                    { name: "Produse", href: "/produse", current: false },
                    {
                        name: `Produs #${route.params.id}`,
                        href: `/produse/${route.params.id}`,
                        current: true,
                    },
                ];
            },
        },
    },
    {
        path: "/produse/:id/modifica",
        name: "products.edit",
        component: () =>
            import(
          /* webpackChunkName: "products.edit" */ "../views/ProductsEdit.vue"
            ),
        beforeEnter: [approvedVendor],
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumbs: (route) => {
                return [
                    { name: "Dashboard", href: "/contul-meu", current: false },
                    { name: "Produse", href: "/produse", current: false },
                    {
                        name: `Produs #${route.params.id}`,
                        href: `/produse/${route.params.id}/modifica`,
                        current: true,
                    },
                ];
            },
        },
    },
];