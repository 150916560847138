import approvedVendor from "./guards/approvedVendor";

//withdrawals routes
export default [
    {
        path: "/retrageri",
        name: "withdrawals.index",
        component: () =>
            import(
          /* webpackChunkName: "withdrawals.index" */ "../views/WithdrawalsIndex.vue"
            ),
        beforeEnter: [approvedVendor],
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Dashboard", href: "/contul-meu", current: false },
                { name: "Retrageri", href: "/retrageri", current: true },
            ],
        },
    },
]