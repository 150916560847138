import approvedVendor from "./guards/approvedVendor";

//rfqs routes
export default [
    {
        path: "/cereri-oferte",
        name: "rfqs.index",
        component: () =>
            import(/* webpackChunkName: "rfqs.index" */ "../views/RfqItemsIndex.vue"),
        beforeEnter: [approvedVendor],
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Dashboard", href: "/contul-meu", current: false },
                { name: "Cereri", href: "/cereri-oferte", current: true },
            ],
        },
    },
    {
        path: "/cereri-oferte/:id/oferte",
        name: "quotations.index",
        component: () =>
            import(
          /* webpackChunkName: "quotations.create" */ "../views/QuotationsIndex.vue"
            ),
        beforeEnter: [approvedVendor],
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumbs: (route) => {
                return [
                    { name: "Dashboard", href: "/contul-meu", current: false },
                    { name: "Cereri", href: "/cereri-oferte", current: false },
                    {
                        name: `Oferta mea`,
                        href: `/cereri-oferte/${route.params.id}/oferte`,
                        current: true,
                    },
                ];
            },
        },
    },
    {
        path: "/cereri-oferte/:id/oferte/adauga",
        name: "quotations.create",
        component: () =>
            import(
          /* webpackChunkName: "quotations.create" */ "../views/QuotationsCreate.vue"
            ),
        beforeEnter: [approvedVendor],
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumbs: (route) => {
                return [
                    { name: "Dashboard", href: "/contul-meu", current: false },
                    { name: "Cereri", href: "/cereri-oferte", current: false },
                    {
                        name: `Oferta mea`,
                        href: `/cereri-oferte/${route.params.id}/oferte`,
                        current: false,
                    },
                    {
                        name: `Adauga oferta`,
                        href: `/cereri-oferte/${route.params.id}/adauga`,
                        current: true,
                    },
                ];
            },
        },
    },
];