import api from "../api/api";
import authApi from "../api/resources/authApi";

export default {
  clearSession() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("approved");
    localStorage.removeItem('vendor_id');
  },

  check() {
    return api.token() !== null;
  },

  isApprovedVendor() {
    return localStorage.getItem('approved');
  },

  saveCredentials(data) {
    localStorage.setItem("token", data.access_token);
    localStorage.setItem("approved", data.approved_vendor);
    this.setVendorId(data.vendor_id);

  },

  setVendorStatus(status) {
    localStorage.setItem("approved", status);
  },

  setVendorId(id) {
    localStorage.setItem("vendor_id", id);
  },

  getCurrentVendorId() {
    return localStorage.getItem('vendor_id');
  },

  register(data) {
    return new Promise((resolve, reject) => {
      authApi.register(data)
        .then((response) => {
          this.saveCredentials(response.data);
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  },

  login(data) {
    return new Promise((resolve, reject) => {
      authApi.login(data)
        .then((response) => {
          this.saveCredentials(response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      authApi.logout(null)
        .then((response) => {
          localStorage.clear();
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.clearSession();
            }
          }
          reject(error);
        });
    });
  },
}