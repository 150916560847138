// import authService from "@/services/authService";

export const API = process.env.VUE_APP_API;

const currentVendorId = localStorage.getItem('vendor_id');
const vendorAPI = API + "vendors/" + currentVendorId + "/";

//auth
export const LOGIN = API + "login";
export const LOGOUT = API + "logout";
export const REGISTER = API + "register";
export const PASSWORDS = API + "passwords";

// look up tables
export const CARS = API + "cars";
export const MAKES = API + "car-makes";
export const MODELS = API + "car-models";
export const YEARS = API + "car-years";
export const COUNTRIES = API + "countries";
export const DISCOUNT_TYPES = API + "vendors/discount_types";
export const ORDER_STATUSES = API + "vendors/order_statuses";
export const MEASUREMENT_UNITS = API + "measurement_units";

// vendors
export const VENDORS = API + "vendors";
export const VENDORS_SHOW = vendorAPI ;
export const VENDOR_ADDRESSES = vendorAPI + "addresses";
export const VENDOR_ATTACHMENTS = vendorAPI + "attachments";
export const VENDOR_STATS = vendorAPI + "stats";

// products
export const VENDOR_PRODUCTS = vendorAPI + "products";
export const VENDOR_PRODUCTS_SHOW = vendorAPI + "products/:product";

// rfq items
export const VENDOR_RFQ_ITEMS = vendorAPI + "rfq-items";
export const VENDOR_RFQ_ITEMS_SHOW = vendorAPI + "rfq-items/:rfqItem";
export const VENDOR_RFQ_ITEMS_QUOTATIONS = vendorAPI + "rfq-items/:rfqItem/quotations";

// quotations
export const VENDOR_QUOTATIONS_SHOW = vendorAPI + "quotations/:quotation"

// orders
export const VENDOR_ORDERS = vendorAPI + "orders";
export const VENDOR_ORDERS_SHOW = vendorAPI + "orders/:order";
export const VENDOR_ORDERS_ITEMS = vendorAPI + "orders/:order/items";
export const VENDOR_ORDERS_PAYMENTS = vendorAPI + "orders/:order/payments";
export const VENDOR_ORDERS_INVOICES = vendorAPI + "orders/:order/invoices";
export const VENDOR_ORDERS_TRANSITIONS = vendorAPI + "orders/:order/transitions";

// transactions
export const VENDOR_TRANSACTIONS = vendorAPI + "transactions";
export const VENDOR_BALANCE = vendorAPI + "balance";

// withdrawals
export const VENDORS_WITHDRAWALS = vendorAPI + "withdrawals";

// vendor's garage/cars
export const VENDOR_CARS = vendorAPI + "cars";
export const VENDOR_CARS_SHOW = vendorAPI + "cars/:car";

// export const ME = API + "me";
// export const VENDOR_OFFER_REQUEST = API + "vendors/rfqs";
// export const VENDOR_RFQS_SHOW = API + "vendors/rfqs/:rfq";
// export const VENDOR_RFQS_ITEMS = API + "vendors/rfqs/:rfq/items";
// export const VENDOR_QUOTATIONS = API + "vendors/quotations";
// export const PAYMENT_METHODS = API + "payments/methods";
// export const CSRF = process.env.VUE_APP_BASE_API + "sanctum/csrf-cookie";