import authService from "../../services/authService";

export default (to, from, next) => {
    const isApproved = authService.isApprovedVendor();

    console.log(isApproved);

    if (isApproved === 'true') {
        next();
    } else if (isApproved === 'undefined') {
        next({ name: "registerVendor.show" });
    } else {
        next({ name: "TaxInformation" });
    }
};