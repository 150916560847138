<template>
  <div class="bg-white h-screen">
    <home-hero />
    <home-benefits />
    <home-statistics />
    <v-footer/>
  </div>
  
</template>

<script>
import HomeStatistics from "../components/HomeStatistics.vue";
import HomeBenefits from "../components/HomeBenefits.vue";
import HomeHero from "../components/HomeHero.vue";
import VFooter from '../layout/VFooter.vue';

export default {
  name: "Home",
  components: {
    HomeHero,
    HomeStatistics,
    HomeBenefits,
    VFooter,
  },
};
</script>
