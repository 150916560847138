import { createRouter, createWebHistory } from "vue-router";
import authService from "../services/authService";

import routes from "./routes";

const defaultRoutes = [{
  path: "/forbidden",
  name: "forbidden",
  component: () =>
    import(/* webpackChunkName: "forbidden" */ "../views/Forbidden.vue"),
},
{
  path: "/:pathMatch(.*)*",
  name: "notFound",
  component: () =>
    import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue"),
},];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes.concat(defaultRoutes),
});

router.resolve({
  name: "notFound",
  params: { pathMatch: ["404"] },
}).href; // '/not/found' G


//globar guards
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authService.check()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (!authService.check()) {
      next();
    } else {
      next({ name: "dashboard.show" });
    }
  } else {
    next(); //make sure to always call next!
  }
});

export default router;
