import noVendor from "./guards/noVendor";
// User routes
export default [
    {
        path: "/register/vendor",
        name: "registerVendor.show",
        component: () =>
            import(
          /* webpackChunkName: "registerVendor.show" */ "../views/RegisterVendor.vue"
            ),
        beforeEnter: [noVendor],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/setari",
        name: "settings",
        component: () =>
            import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Dashboard", href: "/contul-meu", current: false },
                { name: "Setari", href: "/settings", current: true },
            ],
        },
    },
    {
        path: "/informatii-fiscale",
        name: "TaxInformation",
        component: () =>
            import(
          /* webpackChunkName: "informatii-fiscale" */ "../views/TaxInformation.vue"
            ),
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Dashboard", href: "/contul-meu", current: false },
                { name: "Informatii fiscale", href: "/informatii-fiscale", current: true },
            ],
        },
    },
]