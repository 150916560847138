<template>
  <div class="bg-white" id="benefits">
    <div class="contaienr max-w-7xl mx-auto px-4 py-24">
      <div>
        <!-- <p
          class="
            text-base text-yellow-600
            font-semibold
            tracking-wide
            uppercase
            mb-2
          "
        >
          Avantaje
        </p> -->
        <h2
          class="
            text-3xl
            leading-8
            font-bold
            tracking-tight
            text-gray-900
            sm:text-4xl
          "
        >
          Ce avantaje ai?
        </h2>
        <p class="mt-4 max-w-2xl text-xl text-gray-500">
          Mai multi clienti, mai multe vanzari, mai mult profit.
        </p>
      </div>

      <div class="mt-10">
        <dl
          class="
            space-y-10
            md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10
          "
        >
          <div v-for="feature in features" :key="feature.name" class="relative">
            <dt>
              <div
                class="
                  absolute
                  flex
                  items-center
                  justify-center
                  h-12
                  w-12
                  rounded-md
                  bg-yellow-500
                  text-white
                "
              >
                <component
                  :is="feature.icon"
                  class="h-6 w-6"
                  aria-hidden="true"
                />
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">
                {{ feature.name }}
              </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AnnotationIcon,
  GlobeAltIcon,
  CreditCardIcon,
  PresentationChartLineIcon,
  ClockIcon,
  DesktopComputerIcon,
} from "@heroicons/vue/outline";

const features = [
  {
    name: "Vizibilitate online",
    description:
      "Poti primi cereri de oferta constant, 24/24 7 zile pe saptamana.",
    icon: GlobeAltIcon,
  },
  {
    name: "Mai multe vanzari",
    description:
      "Vizibilitatea online iti va aduce mai multe vanzari, si implicit mai mult profit.",
    icon: PresentationChartLineIcon,
  },
  {
    name: "Plati Securizate",
    description: "Tranzacții securizate cu cardul, ori la ramburs.",
    icon: CreditCardIcon,
  },
  {
    name: "Salveaza Timp",
    description:
      "Pe alte platforme trebuie sa incarci toate piesele. Aici incarci doar piesa de care clientul are nevoie.",
    icon: ClockIcon,
  },
  {
    name: "Notificari Automate",
    description:
      "Te înștiințam atunci cand clientul accepta piesa oferita de tine.",
    icon: AnnotationIcon,
  },
  {
    name: "Interfață Prietenoasă",
    description:
      "Interfață este special concepută pentru a fi modernă, intuitivă și rapidă.",
    icon: DesktopComputerIcon,
  },
];

export default {
  setup() {
    return {
      features,
    };
  },
};
</script>
