import approvedVendor from "./guards/approvedVendor";

//orders routes
export default [
    {
        path: "/comenzi",
        name: "orders.index",
        component: () =>
            import(/* webpackChunkName: "orders.index" */ "../views/OrdersIndex.vue"),
        beforeEnter: [approvedVendor],
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Dashboard", href: "/contul-meu", current: false },
                { name: "Comenzi", href: "/comenzi", current: true },
            ],
        },
    },
    {
        path: "/comenzi/:id",
        props: true,
        name: "orders.show",
        component: () =>
            import(/* webpackChunkName: "orders.show" */ "../views/OrdersShow.vue"),
        beforeEnter: [approvedVendor],
        meta: {
            requiresAuth: true,
            breadcrumbs: (route) => {
                return [
                    { name: "Dashboard", href: "/contul-meu", current: false },
                    { name: "Comenzi", href: "/comenzi", current: false },
                    {
                        name: `Comanda #${route.params.id}`,
                        href: `/comenzi/${route.params.id}`,
                        current: true,
                    },
                ];
            },
        },
    },

    {
        path: "/comenzi/:id/facturi/adauga",
        name: "orders.invoices.create",
        component: () =>
            import(
          /* webpackChunkName: "orders.invoices.create" */ "../views/OrdersInvoicesCreate.vue"
            ),
        beforeEnter: [approvedVendor],
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumbs: (route) => {
                return [
                    { name: "Dashboard", href: "/contul-meu", current: false },
                    { name: "Comenzi", href: "/comenzi", current: false },
                    {
                        name: `Comanda #${route.params.id}`,
                        href: `/comenzi/${route.params.id}`,
                        current: false,
                    },
                    {
                        name: `Adauga factura`,
                        href: `/comenzi/${route.params.id}/facturi/adauga`,
                        current: true,
                    },
                ];
            },
        },
    },
]