import approvedVendor from "./guards/approvedVendor";

//transactions routes
export default [
    {
        path: "/tranzactii",
        name: "transactions.index",
        component: () =>
            import(
          /* webpackChunkName: "transactions.index" */ "../views/TransactionsIndex.vue"
            ),
        beforeEnter: [approvedVendor],
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Dashboard", href: "/contul-meu", current: false },
                { name: "Tranzactii", href: "/tranzactii", current: true },
            ],
        },
    },
]