import approvedVendor from "./guards/approvedVendor";

//vendor cars routes
export default [
    {
        path: "/masinile-mele",
        name: "cars.index",
        component: () =>
            import(
          /* webpackChunkName: "cars.index" */ "../views/VendorCarsIndex.vue"
            ),
        beforeEnter: [approvedVendor],
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Dashboard", href: "/contul-meu", current: false },
                { name: "Masinile mele", href: "/masinile-mele", current: true },
            ],
        },
    },
    {
        path: "/masinile-mele/adauga",
        name: "cars.create",
        component: () =>
            import(
          /* webpackChunkName: "cars.create" */ "../views/VendorCarsCreate.vue"
            ),
        beforeEnter: [approvedVendor],
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Dashboard", href: "/contul-meu", current: false },
                { name: "Masinile mele", href: "/masinile-mele", current: false },
                { name: "Adauga masina", href: "/masinile-mele/adauga", current: true },
            ],
        },
    },
]