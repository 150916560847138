import authService from "../../services/authService";

export default (to, from, next) => {
    const isApproved = authService.isApprovedVendor();

    if ((isApproved === 'undefined') || (isApproved === 'null') ||  (isApproved === false)) {
        next();
    } else {
        next({ name: "TaxInformation" });
    }
};