import Home from "../views/Home.vue";
// Guest routes
export default [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Login.vue"),
        meta: {
            guest: true,
        },
    },
    {
        path: "/register",
        name: "register",
        component: () =>
            import(/* webpackChunkName: "register" */ "../views/Register.vue"),
        meta: {
            guest: true,
        },
    },
];